<template>
<div class="announcement page">
  <Headers title="公告"></Headers>
  <div class="scroll">
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
        <p>{{index+1}}、{{item.title}}</p>
        <van-icon name="arrow" color="rgb(213, 213, 213)" size="20"/>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import Headers from "@/components/headers.vue";
export default {
  name: "announcement",
  components: {
    Headers
  },
  mounted() {
    this.getList()
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    getList(){
      $api.gonggao({
        token: this.$store.getters["getToken"]
      }).then(res => {
        res.data.length>0 && (this.list = res.data)
      }).catch(err => {

      })
    },
    toDetail(id){
      this.$router.push({
        path: '/newdetails',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.announcement {
  background: #fff;
  .list {
    padding: 10px 15px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      font-size: 16px;
    }
  }
}
</style>